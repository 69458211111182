/* src/styles/fonts.css */
@font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-BookItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-SemiboldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-Heavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cooper Hewitt';
    src: url('/public/fonts/CooperHewitt-HeavyItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
  }
  
  .font-cooper {
    font-family: 'Cooper Hewitt', sans-serif;
  }