@tailwind base;
@tailwind components;
@tailwind utilities;

/* Cooper Hewitt Font */
@font-face {
  font-family: 'Cooper Hewitt';
  src: url('/public/fonts/CooperHewitt-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Cooper Hewitt';
  src: url('/public/fonts/CooperHewitt-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Cooper Hewitt';
  src: url('/public/fonts/CooperHewitt-Book.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cooper Hewitt';
  src: url('/public/fonts/CooperHewitt-BookItalic.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Cooper Hewitt';
  src: url('/public/fonts/CooperHewitt-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cooper Hewitt';
  src: url('/public/fonts/CooperHewitt-MediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}

/* Global styles */
body {
  @apply bg-white text-black;
}